<template>
  <!-- 中台业务账号 -->
  <div class="container">
    <a-tabs :default-active-key="tabIndex" :animated="false" @change="callback">
      <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.name">
        <div class="main-content">
          <div class="main-content-header">
            <div class="item">
              <span class="title">{{ item.name }}账号</span>
            </div>
          </div>
          <my-table
            :columns="columns"
            :data-source="item.data"
            :pagination="false"
            :loading="item.loading"
            :scroll="{ x: 1280, y: true }"
          >
            <template slot="username" slot-scope="record">
              <span v-if="tabIndex === 2">{{ record.realName }}</span>
              <span v-else>{{ record.username }}</span>
            </template>
            <template slot="status" slot-scope="record">
              <a-badge
                :status="statuText[record.status].statu"
                :text="statuText[record.status].text"
              />
            </template>
            <template slot="action" slot-scope="record">
              <router-link :to="`asd?id=${record.id}`">
                查看账号
              </router-link>
            </template>
          </my-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import myTable from '@/components/my-table'
export default {
  data() {
    return {
      tabs: [
        {
          key: 0,
          id: 'Proxy',
          name: '企业',
          data: [],
          loading: true
        },
        {
          key: 1,
          id: 'Lector',
          name: '讲师',
          data: [],
          loading: true
        },
        {
          key: 2,
          id: 'business',
          name: '渠道商',
          data: [],
          loading: true
        },
         {
          key: 3,
          id: 'store',
          name: '门店',
          data: [],
          loading: true
        },
         {
          key: 4,
          id: 'community',
          name: '社区',
          data: [],
          loading: true
        },
         {
          key: 5,
          id: 'fowller',
          name: '监工',
          data: [],
          loading: true
        },
        
      ],
      columns: [
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '登录账号',
          dataIndex: 'systemNum',
          width: 220
        },
        {
          title: '持有者姓名',
          width: 150,
          scopedSlots: { customRender: 'username' }
        },
        {
          title: '所属角色',
          dataIndex: 'role_name',
          width: 150,
          customRender: (text) => {
            return text ? text : '-'
          }
        },
        {
          title: '角色等级',
          dataIndex: 'level_name',
          width: 100,
          customRender: (text) => {
            return text ? text : '-'
          }
        },
        {
          title: '创建时间',
          dataIndex: 'createAt',
          width: 200
        },
        {
          title: '账号状态',
          scopedSlots: { customRender: 'status' },
          width: 100
        },
        {
          title: '备注',
          dataIndex: 'remark',
          customRender: (text) => {
            return text ? text : '-'
          }
        },
        {
          title: '操作',
          fixed: 'right',
          width: 100,
          scopedSlots: { customRender: 'action' }
        }
      ],
      tabIndex: 0,
      statuText: [
        {
          text: '禁用',
          statu: 'default'
        },
        {
          text: '启用',
          statu: 'success'
        }
      ]
    }
  },
  computed: {
    ...mapState(['pageTitle'])
  },
  components: {
    myTable
  },
  created() {
    this.getAccount('Proxy')
  },
  methods: {
    callback(key) {
      // tab切换
      this.tabIndex = key
      this.getAccount()
    },
    getAccount() {
      // 获取账号列表
      const tab = this.tabs[this.tabIndex]
      const data = {
        type: tab.id
      }
      this.$axios.getAccount(data).then((res) => {
        const d = res.data.data
        tab.data = d
        tab.loading = false
      })
    }
  }
}
</script>
